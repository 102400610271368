import { zoundBarInstanceV2, buildFormData } from '../zoundBarApiV2.service'

const resource = 'roles/waiter/'

export const WaiterService = {
  getWaiters () {
    return zoundBarInstanceV2.get(`${resource}`)
  },
  postWaiterFormData (data) {
    const auxFormData = buildFormData(data)
    return zoundBarInstanceV2.post(resource, auxFormData)
  },
  postWaiter (data) {
    return zoundBarInstanceV2.post(resource, data)
  },
  updateWaiterFormData (productId, data) {
    const auxFormData = buildFormData(data)
    return zoundBarInstanceV2.put(`${resource}edit/${productId}`, auxFormData)
  },
  deleteWaiter (waiterId) {
    return zoundBarInstanceV2.delete(`${resource}edit/${waiterId}`)
  }
}
