import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'booking/schedule'

export const BookingScheduleService = {
  index () {
    return zoundBarInstanceV2.get(`${resource}/`)
  },
  create ({ day, hour, discount, people }) {
    return zoundBarInstanceV2.post(`${resource}/`, { day, hour, discount, people })
  },
  createBatch ({ day, schedule }) {
    return zoundBarInstanceV2.post(`${resource}/batch`, { day, schedule })
  },
  schedule (day) {
    return zoundBarInstanceV2.get(`${resource}/${day}`)
  }
}
