import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'settings/images/map'

export const MapService = {
  get (page, type, itemsPerPage) {
    return zoundBarInstanceV2.get(`${resource}`)
  },
  post (data) {
    return zoundBarInstanceV2.post(`${resource}`, data)
  },
  delete (mapId) {
    return zoundBarInstanceV2.delete(`${resource}/${mapId}`)
  }
}
