export const authMutations = {
  LOGIN_REQUEST (state, data) {
    state.status = { loggingIn: true }
    state.data = data
  },
  LOGIN_SUCCESS (state, data) {
    state.status = { loggedIn: true }
    state.data = data.user
    localStorage.setItem('authUser', JSON.stringify(data.user))
  },
  LOGIN_FAIL (state) {
    state.status = {}
    state.data = null
  },
  LOGOUT (state) {
    state.status = {}
    state.data = null
    localStorage.removeItem('authUser')
  }
}
