/**
  * @example Example of use Component
  * **********************************
  *   <zb-warning-modal v-model="dialog" icon="fas fa-trash">
  *     Text questions for delete ¿?
  *     <template slot="actions">
  *       <v-btn color="secondary-20" @click="dialog = false">No</v-btn>
  *       <v-btn color="error" @click="event()">Yes</v-btn>
  *     </template>
  *   </zb-warning-modal>
  */

export default {
  name: 'zb-warning-modal',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fas fa-trash'
    }
  },
  methods: {
    dialogEvent () {
      this.$emit('input')
    }
  }
}
