export const getters = {
  barId$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.zbBarId
    } else {
      return ''
    }
  },
  titleBar$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.name
    } else {
      return ''
    }
  },
  telephone$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.telephone
    } else {
      return ''
    }
  },
  socialMedia$ (state) {
    return state.dataSettings.settings.socialMedia
  },
  bannerBarUrl$ (state) {
    return state.dataSettings.settings.images.banner
  },
  profileBarUrl$ (state) {
    return state.dataSettings.settings.images.profile
  },
  workingSchedule$ (state) {
    return state.dataSettings.settings.workingSchedule
  },
  location$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.location
    } else {
      return {}
    }
  },
  slogan$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.slogan
    } else {
      return ''
    }
  },
  maxPeopleCapacity$ (state) {
    if (state.dataSettings.success) {
      return state.dataSettings.settings.maxPeopleCapacity
    } else {
      return 0
    }
  },
  address$ (state) {
    return state.dataSettings.settings.address
  },
  typeBusiness (state) {
    return state.dataSettings.settings.typeBusiness
  },
  musicAutoplay (state) {
    return state.dataSettings.settings.musicAutoplay
  }
}
