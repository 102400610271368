import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker, MglNavigationControl } from 'vue-mapbox'

export default {
  components: {
    MglMap,
    MglMarker,
    MglNavigationControl
  },
  data () {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_API_KEY, // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
      showMarker: true
    }
  },
  props: {
    zoom: {
      type: Number,
      default: 5
    },
    coordinates: {
      type: Array,
      required: true
    },
    draggable: {
      type: Boolean,
      required: false
    }
  },
  created () {
    this.mapbox = Mapbox
  },
  methods: {
    MglMarkerAddRemove () {
      this.showMarker = !this.showMarker
    },
    dragEvent (e) {
      this.$emit('drag', {
        longitude: e.marker._lngLat.lng,
        latitude: e.marker._lngLat.lat
      })
    },
    dragEndEvent (e) {
      this.$emit('dragend', {
        longitude: e.marker._lngLat.lng,
        latitude: e.marker._lngLat.lat
      })
    },
    addedEvent (e) {
      this.$emit('added', {
        longitude: e.marker._lngLat.lng,
        latitude: e.marker._lngLat.lat
      })
    }
  }
}
