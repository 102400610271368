import { zoundBarInstanceV2, buildFormData } from '../zoundBarApiV2.service'

const resource = 'settings/profile/'

export const SettingsProfileService = {
  get () {
    return zoundBarInstanceV2.get(resource)
  },
  put (payload) {
    return zoundBarInstanceV2.put(`${resource}`, payload)
  },
  postImageProfileFormData (data) {
    const auxFormData = buildFormData(data)
    return zoundBarInstanceV2.post(`${resource}image`, auxFormData)
  }
}
