import axios from 'axios'

import store from '../store'

export const zoundBarInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

zoundBarInstance.interceptors.request.use((config) => {
  const authUser = store.state.authModule.data
  if (authUser && authUser.token) {
    config.headers['Authorization'] = `Bearer ${authUser.token}`
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

zoundBarInstance.interceptors.response.use(
  response => {
    if (response.data.success) {
      return response.data
    } else {
      return Promise.reject(response.data)
    }
  },
  error => {
    return Promise.reject(error.response.data)
  }
)

export const buildFormData = (data) => {
  let formData = new FormData()
  if (!Array.isArray(data)) {
    for (const key in data) {
      formData.append(key, data[key])
    }
  }
  return formData
}
