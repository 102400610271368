import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'bank-account'

export const BankAccountService = {
  get () {
    return zoundBarInstanceV2.get(`${resource}/`)
  },
  put (payload) {
    return zoundBarInstanceV2.put(`${resource}/`, payload)
  }
}
