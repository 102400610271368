import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'booking/settings/'

export const SettingsBookingService = {
  get () {
    return zoundBarInstanceV2.get(`${resource}`)
  },
  put (payload) {
    return zoundBarInstanceV2.put(`${resource}edit`, payload)
  }
}
