import Vue from 'vue'
import VueRouter from 'vue-router'
import { ZbAdminRouting } from '@/modules/zb-admin/ZbAdminRouting'
import store from '../store'
import { ServiceFactory } from '@/services/ServiceFactory'
const _settingsService = ServiceFactory.get('SettingsService')

Vue.use(VueRouter)

const loginToAdmin = (to, from, next) => {
  const authUser = store.state.authModule.data
  if (authUser && authUser.token) {
    next()
    return
  }
  next({ name: 'login' })
}

const adminToLogin = (to, from, next) => {
  const authUser = store.state.authModule.data
  if (authUser && authUser.token) {
    next({ name: 'admin' })
    return
  }
  next()
}

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: 'admin/bookings',
    children: ZbAdminRouting,
    beforeEnter: loginToAdmin,
    component: () => import('@/views/ZbAdmin.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/ZbLogin.vue'),
    beforeEnter: adminToLogin
  },
  {
    path: '/resetpassword',
    name: 'resetPassword',
    component: () => import('@/views/ZbResetPassword'),
    beforeEnter: adminToLogin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && to.name !== 'resetPassword') {
    try {
      const data = await _settingsService.get()
      store.state.authModule.modules = data.modules
      const authUser = data
      const { module } = to.meta
      if (authUser) {
        const authModules = authUser.modules.map((v) => { return v.module })
        if (authModules.includes(module[0])) {
          next()
        } else {
          next(false)
        }
      } else {
        next({ name: 'login' })
      }
    } catch (error) {
      // if error on getting settings, send to login
      next({ name: 'login' })
    }
  } else if (to.name === 'resetPassword' && to.name !== 'login') {
    next()
  } else {
    next()
  }
})

export default router
