import { zoundBarInstance, buildFormData } from '../zoundBarApi.service'
import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'
const resource = 'menu/'

export const ProductService = {
  getProducts (category) {
    return zoundBarInstanceV2.get(`${resource}?category=${category}`)
  },
  post (payload) {
    return zoundBarInstanceV2.post(resource, payload)
  },
  delete (productId) {
    return zoundBarInstanceV2.delete(`${resource}product/${productId}`)
  },
  put (productId, payload) {
    return zoundBarInstance.put(`${resource}product/${productId}`, payload)
  },
  createProductFormData (data) {
    const auxFormData = buildFormData(data)
    return zoundBarInstanceV2.post(resource, auxFormData)
  },
  updateProductFormData (productId, data) {
    const auxFormData = buildFormData(data)
    return zoundBarInstanceV2.put(`${resource}product/${productId}`, auxFormData)
  },
  putAvailability (productId, data) {
    return zoundBarInstanceV2.put(`${resource}product/available/${productId}`, data)
  },
  putDiscountAvailability (productId, data) {
    return zoundBarInstanceV2.put(`${resource}product/discount/${productId}`, data)
  }
}
