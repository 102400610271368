export const state = {
  dataSettings: {
    success: false,
    settings: {
      zbBarId: '',
      name: '',
      slogan: '',
      telephone: '',
      socialMedia: [
        {
          name: 'website',
          url: ''
        },
        {
          name: 'facebook',
          url: ''
        },
        {
          name: 'instagram',
          url: ''
        }
      ],
      maxPeopleCapacity: 20,
      images: {
        profile: `${process.env.BASE_URL}images/user-default.png`,
        banner: `${process.env.BASE_URL}images/banner-default.png`
      },
      typeBusiness: '',
      musicAutoplay: null
    }
  }
}
