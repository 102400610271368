import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'menu/types'

export const TypesProductsService = {
  get () {
    return zoundBarInstanceV2.get(`${resource}/`)
  },
  getOne (id) {
    return zoundBarInstanceV2.get(`${resource}/${id}`)
  },
  post (payload) {
    return zoundBarInstanceV2.post(`${resource}/`, payload)
  },
  put (id, payload) {
    return zoundBarInstanceV2.put(`${resource}/${id}`, payload)
  },
  delete (id) {
    return zoundBarInstanceV2.delete(`${resource}/${id}`)
  }
}
