<template>
  <v-app class="tw-font-body">
    <router-view></router-view>
    <notifications class="mr-5 mt-5" position="top right"/>
  </v-app>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
html { overflow: auto !important; }
</style>
