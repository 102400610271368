import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'bar-area'

export const AreaTableService = {
  get () {
    return zoundBarInstanceV2.get(`${resource}/`)
  },
  getTables () {
    return zoundBarInstanceV2.get(`${resource}/table`)
  },
  postArea (payload) {
    return zoundBarInstanceV2.post(`${resource}/`, payload)
  },
  postTable (payload) {
    return zoundBarInstanceV2.post(`${resource}/table`, payload)
  },
  putTable (tableId, payload) {
    return zoundBarInstanceV2.put(`${resource}/table/${tableId}`, payload)
  },
  deleteTable (tableId) {
    return zoundBarInstanceV2.delete(`${resource}/table/${tableId}`)
  },
  putArea (areaId, payload) {
    return zoundBarInstanceV2.put(`${resource}/${areaId}`, payload)
  },
  deleteArea (areaId) {
    return zoundBarInstanceV2.delete(`${resource}/${areaId}`)
  }
}
