import Vue from 'vue'
// import _ from 'lodash'
export const authActions = {
  /**
   * Login action method for get credentials
   * @param commit
   * @param dispatch
   * @param url Url to login
   * @param username username field
   * @param password password field
   */
  login: async ({ commit, dispatch }, { url, email, password }) => {
    commit('LOGIN_REQUEST', { email })
    try {
      const resp = await Vue.prototype.$zoundApiServiceV2.post(url, { email, password })
      await commit('LOGIN_SUCCESS', resp)
      return true
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Advertencia!!',
        type: 'error',
        text: error.msg
      })
      commit('LOGIN_FAIL', error)
      return false
    }
  },
  /**
   * Logout action method for remove credential go to login page
   * @param commit
   * @param url
   */
  logout: async ({ commit, dispatch }, { url }) => {
    try {
      await Vue.prototype.$zoundApiServiceV2.post(url)
      await commit('LOGOUT')
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Error!!',
        type: 'error',
        text: error.msg
      })
    }
  },
  /**
   *
   */
  sendPasswordEmail: async ({ commit }, { url, email }) => {
    try {
      const res = await Vue.prototype.$zoundApiServiceV2.post(url, { email })
      Vue.prototype.$notify({
        title: 'Exito',
        type: 'success',
        text: res.msg,
        duration: 5000
      })
    } catch (error) {
      Vue.prototype.$notify({
        title: 'Advertencia',
        type: 'error',
        text: error.msg
      })
    }
  }
}
