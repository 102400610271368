import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'
// import moment from 'moment'
const resource = 'billing'

export const BillingService = {
  getMusic () {
    return zoundBarInstanceV2.get(`${resource}/music/`)
  },
  getBooking () {
    return zoundBarInstanceV2.get(`${resource}/booking/`)
  },
  showReservations (date) {
    let dateString = date
    switch (date.split('-')[1]) {
      case '01':
        dateString = '02'
        break
      case '02':
        dateString = '03'
        break
      case '03':
        dateString = '04'
        break
      case '04':
        dateString = '05'
        break
      case '05':
        dateString = '06'
        break
      case '06':
        dateString = '07'
        break
      case '07':
        dateString = '08'
        break
      case '08':
        dateString = '09'
        break
      case '09':
        dateString = '10'
        break
      case '10':
        dateString = '11'
        break
      case '11':
        dateString = '12'
        break
      case '12':
        dateString = '01'
        break
      default:
        break
    }
    const dateStart = date + '-01'
    const dateEnd = date + '-' + new Date(date.split('-')[0] + '-' + dateString).getDate()
    return zoundBarInstanceV2.get(`${resource}/booking/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
  }
}
