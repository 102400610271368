import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'order'

export const TableOrdersService = {
  get (page, type, itemsPerPage) {
    return zoundBarInstanceV2.get(`${resource}/?page=${page}&type=${type}&itemsPerPage=${itemsPerPage}`)
  },
  getRecent () {
    return zoundBarInstanceV2.get(`${resource}/recent`)
  },
  post (data) {
    return zoundBarInstanceV2.post(`${resource}/`, data)
  },
  put (idOrder, data) {
    return zoundBarInstanceV2.put(`${resource}/${idOrder}`, data)
  },
  delete (idOrder, data) {
    return zoundBarInstanceV2.delete(`${resource}/${idOrder}`, { data })
  }
}
