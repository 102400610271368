import { langState } from './lang.state'
import { langMutations } from './lang.mutations'
import { langActions } from './lang.actions'
import { langGetters } from './lang.getters'

export default {
  namespaced: true,
  state: langState,
  mutations: langMutations,
  actions: langActions,
  getters: langGetters
}
