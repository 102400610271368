export default {
  inserted: function (el, binding, vnode) {
    const modulesArray = vnode.context.$store.state.authModule.modules.map((v, k) => {
      return v.module
    })
    if (!modulesArray.includes(binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
}
