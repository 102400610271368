export default {
  inserted: function (el, binding, vnode) {
    let UserPermisions = []
    const [ ModuleFilter ] = vnode.context.$store.state.authModule.modules.filter((v) => v.module === vnode.context.$route.meta.module[0])
    const { permissions } = ModuleFilter
    if (permissions.CREATE === 'GRANTED') { UserPermisions = [...UserPermisions, 'CREATE'] }
    if (permissions.DELETE === 'GRANTED') { UserPermisions = [...UserPermisions, 'DELETE'] }
    if (permissions.EDIT === 'GRANTED') { UserPermisions = [...UserPermisions, 'EDIT'] }
    if (permissions.VIEW === 'GRANTED') { UserPermisions = [...UserPermisions, 'VIEW'] }
    if (!UserPermisions.some(r => binding.value.includes(r))) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
}
