export const langActions = {
  /**
   * @param commit
   * @param dispatch
   * @param url
   * @param username
   * @param password
   */
  setLang: ({ commit, dispatch }, { currentLang }) => {
    commit('SET_LANG', { currentLang })
  }
}
