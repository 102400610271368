import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'settings/'

export const SettingsService = {
  get () {
    return zoundBarInstanceV2.get(resource)
  },
  postImage (data) {
    return zoundBarInstanceV2.post(`${resource}image`, data)
  },
  put (data) {
    return zoundBarInstanceV2.put(`${resource}`, data)
  },
  updatePassword (data) {
    return zoundBarInstanceV2.put(`${resource}security`, data)
  },
  getClosingDays () {
    return zoundBarInstanceV2.get(`${resource}closing-days/`)
  },
  saveClosingDays (data) {
    return zoundBarInstanceV2.post(`${resource}closing-days/`, data)
  },
  deleteClosingDays () {
    return zoundBarInstanceV2.delete(`${resource}closing-days/`)
  },
  getDebt () {
    return zoundBarInstanceV2.get(`${resource}debt/`)
  }
}
