// const ZbBarProfileComponent = () => import('../bar-profile')
const ZbFormBarProfileComponent = () => import('./tab-bar-profile/form-bar-profile')
const ZbTabBarProfileComponent = () => import('./tab-bar-profile')

export const ZbBarProfileRouting = [
  {
    meta: { module: ['SETTINGS'], icon: 'far fa-building' },
    path: 'tab-bar-profile',
    name: 'tab-bar-profile',
    component: ZbTabBarProfileComponent
  },
  {
    meta: { module: ['SETTINGS'], icon: 'far fa-building' },
    path: 'form-bar-profile',
    name: 'form-bar-profile',
    component: ZbFormBarProfileComponent
  }
]
