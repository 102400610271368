import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import zoundBarApiServices from './services'
import vuetify from './plugins/vuetify/'
import vuelidate from './plugins/vuelidate'
import veevalidate from './plugins/veevalidate'
import vuecroppie from './plugins/vuecroppie'
import sweetalert from './plugins/sweetalert'
import vuechatscroll from './plugins/vuechatscroll'
import vueqr from './plugins/vue-qr'
// import vueonesignal from './plugins/vue-onesignal'
import 'croppie/croppie.css'
import './common/components'
import './common/directives'
import i18n from './plugins/i18n'
import vuenotification from './plugins/vuenotification'
import vuehtml from './plugins/vue-html-to-paper'
import './plugins/tailwind/index.scss'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  vuelidate,
  sweetalert,
  vuecroppie,
  veevalidate,
  vuechatscroll,
  vuenotification,
  vuehtml,
  vueqr,
  zoundBarApiServices,
  render: h => h(App)
}).$mount('#app')
