import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'takeout-order'

export const OrdersService = {
  get (page, type, itemsPerPage) {
    return zoundBarInstanceV2.get(`${resource}/?page=${page}&type=${type}&itemsPerPage=${itemsPerPage}`)
  },
  put (idOrder, data) {
    return zoundBarInstanceV2.put(`${resource}/${idOrder}`, data)
  },
  delete (idOrder, data) {
    return zoundBarInstanceV2.delete(`${resource}/${idOrder}`, { data })
  }
}
