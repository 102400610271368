import { ZbBarProfileRouting } from './bar-profile/ZbBarProfileRouting'
const HomeComponent = () => import('./home')
const ZbProductComponent = () => import('./product-tickets')
const ZbCustomizationComponent = () => import('./customization')
const ZbBarProfileComponent = () => import('./bar-profile')
const ZbBookingsComponent = () => import('./bookings')
const ZbWaitersComponent = () => import('./waiters')
const ZbSubscribersComponent = () => import('./subscribers')
const ZbTableOrdersComponent = () => import('./table-orders')
const ZbOrdersComponent = () => import('./orders')
const ZbTablesEnvsComponent = () => import('./tables-envs')
const BillingComponent = () => import('./billing')
export const ZbAdminRouting = [
  { meta: { module: ['BOOKING'], icon: '$iconListCheck' }, path: '/admin/bookings', name: 'bookings', component: ZbBookingsComponent },
  {
    meta: { module: ['SETTINGS'], icon: 'fas fa-home' },
    path: '/admin/home',
    name: 'home',
    component: HomeComponent
  },
  {
    meta: { module: ['SETTINGS'], icon: null },
    path: '/admin/profile',
    name: 'bar-profile',
    redirect: 'profile/tab-bar-profile',
    component: ZbBarProfileComponent,
    children: ZbBarProfileRouting
  },
  { meta: { module: ['SETTINGS'], icon: 'fas fa-cogs' }, path: '/admin/customization', name: 'customization', component: ZbCustomizationComponent },
  { meta: { module: ['MENU'], icon: 'fas fa-store-alt' }, path: '/admin/product-tickets', name: 'menu', component: ZbProductComponent },
  { meta: { module: ['TEAM'], icon: '$iconWaiter' }, path: '/admin/waiters', name: 'waiters', component: ZbWaitersComponent },
  { meta: { icon: 'fas fa-user-check' }, path: '/admin/subscribers', name: 'subscribers', component: ZbSubscribersComponent },
  { meta: { module: ['TABLE'], icon: 'fas fa-utensils' }, path: '/admin/table-orders', name: 'tableOrders', component: ZbTableOrdersComponent },
  { meta: { module: ['ORDERS'], icon: 'fas fa-clipboard' }, path: '/admin/orders', name: 'orders', component: ZbOrdersComponent },
  { meta: { module: ['TABLE'], icon: 'fas fa-chair' }, path: '/admin/tables-envs', name: 'tables', component: ZbTablesEnvsComponent },
  { meta: { module: ['SETTINGS'], icon: 'fas fa-file-invoice-dollar' }, path: '/admin/billing', name: 'billing', component: BillingComponent }
]
