import Vue from 'vue'
import { extend } from 'vee-validate'
import { required, max, email, between, numeric, min } from 'vee-validate/dist/rules'
import { isURL } from 'validator'
extend('required', {
  ...required,
  message: 'El campo es requerido'
  // message: '{_field_} es requerido'
})

extend('max', {
  ...max,
  message: 'El campo no puede tener más de {length} caracteres'
})

extend('min', {
  ...min,
  message: 'El campo no puede tener menos de {length} caracteres'
})

extend('between', {
  ...between,
  message: 'El campo solo acepta un rango de números de {min} a {max}'
})

extend('numeric', {
  ...numeric,
  message: 'El campo solo acepta números'
})

extend('url', {
  validate: isURL,
  message: 'El campo solo acepta url (Por ejemplo: https://ejemplo.com)'
})

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
    return {
      valid: regex.test(value)
    }
  },
  message: 'El campo debe contener solo numeros enteros ó decimales'
})

extend('email', {
  ...email,
  message: 'Email must be valid'
})

Vue.use({ extend }, { inject: false })

export default { extend }
