import Vue from 'vue'
import ZbWarningModal from './zb-warning-modal'
// import ZbWindowModal from './zb-window-modal/ZbWindowModal'
import VueTinySlider from 'vue-tiny-slider'
import MapboxComponent from './mapbox-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('zb-warning-modal', ZbWarningModal)
Vue.component('zb-tiny-slider', VueTinySlider)
Vue.component('mapbox-component', MapboxComponent)
