import { ProductService } from './products/products.service'
import { AdminService } from './admin/admin.service'
import { SettingsService } from './settings/settings.service'
import { WaiterService } from './waiters/waiters.service'
import { BookingService } from './booking/booking.service'
import { SettingsBookingService } from './booking/settings.service'
import { TablesBookingService } from './booking/tables.service'
import { BookingScheduleService } from './booking/schedule.service'
import { SettingsProfileService } from './settings/profile.service'
import { BillingService } from './billing/billing.service'
import { SecurityMeasuresService } from './security-measures/securityMeasures.service'
import { SubscribersService } from './subscribers/subscribers.service'
import { BankAccountService } from './bank-account/bankAccount.service'
import { AreaTableService } from './area/area.service'
import { TypesProductsService } from './type-products/type-products.service'
import { ModulesService } from './modules/modules.service'
import { TableOrdersService } from './table-orders/table-orders.service'
import { OrdersService } from './orders/orders.service'
import { MapService } from './map/map.service'
import { SpecialConditionsService } from './special-conditions/special-conditions.service'

const repositories = {
  ProductService,
  AdminService,
  SettingsService,
  WaiterService,
  BookingService,
  BookingScheduleService,
  SettingsBookingService,
  SettingsProfileService,
  BillingService,
  SecurityMeasuresService,
  SubscribersService,
  BankAccountService,
  AreaTableService,
  TypesProductsService,
  TableOrdersService,
  ModulesService,
  OrdersService,
  MapService,
  TablesBookingService,
  SpecialConditionsService
}

export const ServiceFactory = {
  get: name => repositories[name]
}
