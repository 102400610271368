import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'settings/special-conditions/'

export const SpecialConditionsService = {
  index () {
    return zoundBarInstanceV2.get(resource)
  },
  create ({ name }) {
    return zoundBarInstanceV2.post(`${resource}`, { name })
  },
  edit (id, { name }) {
    return zoundBarInstanceV2.put(`${resource}/${id}`, { name })
  },
  destroy (id) {
    return zoundBarInstanceV2.delete(`${resource}/${id}`)
  }
}
