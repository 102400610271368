import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'

const resource = 'booking'

export const BookingService = {
  get (page, type, itemsPerPage) {
    return zoundBarInstanceV2.get(`${resource}/?page=${page}&type=${type}&itemsPerPage=${itemsPerPage}`)
  },
  put (idBooking, action = 'complete') {
    return zoundBarInstanceV2.put(`${resource}/edit/${idBooking}?action=${action}`)
  },
  getCalendarData (month) {
    return zoundBarInstanceV2.get(`${resource}/calendar/?month=${month}`)
  },
  getDetailDate (date) {
    return zoundBarInstanceV2.get(`${resource}/calendar/check?date=${date}`)
  },
  disableHour (data) {
    return zoundBarInstanceV2.delete(`${resource}/calendar/cancel`, { data })
  },
  getStats (type = [], dateStart = '', dateEnd = '') {
    return zoundBarInstanceV2.get(`${resource}/stats/?type=${type.join()}&dateStart=${dateStart}&dateEnd=${dateEnd}`)
  },
  getReviews () {
    return zoundBarInstanceV2.get(`${resource}/reviews`)
  },
  getAdminSettings () {
    return zoundBarInstanceV2.get(`${resource}/settings/`)
  },
  getScheduleAvailable (date) {
    return zoundBarInstanceV2.get(`${resource}/calendar/check?date=${date}`)
  },
  saveSchedule (payload) {
    return zoundBarInstanceV2.post(`${resource}/`, payload)
  },
  getBookingsPerDay (type = 'all', startDate, endDate) {
    return zoundBarInstanceV2.get(`${resource}/stats/?type=${type}&dateStart=${startDate}&dateEnd=${endDate}`)
  },
  saveCode (code) {
    return zoundBarInstanceV2.put(`${resource}/code/${code}`)
  },
  cancelBooking (idBooking, data) {
    return zoundBarInstanceV2.delete(`${resource}/${idBooking}`, { data })
  }
}
