import Vue from 'vue'
import axios from 'axios'
import { zoundBarInstance } from './zoundBarApi.service'
import { zoundBarInstanceV2 } from './zoundBarApiV2.service'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.prototype.$zoundApiService = zoundBarInstance
Vue.prototype.$zoundApiServiceV2 = zoundBarInstanceV2
export default new VueAxios()
