<template>
  <svg xmlns:xlink="http://www.w3.org/1999/xlink" width="337.32" id="Layer_1" data-name="Layer 1" viewBox="0 0 337.32 639.11">
    <defs>
      <linearGradient id="linear-gradient" x1="82.26" y1="310.02" x2="290.89" y2="301.12" gradientUnits="userSpaceOnUse">
        <stop class="linear-1" offset="0" />
        <stop class="linear-2" offset="0.12" />
        <stop class="linear-3" offset="0.9" />
      </linearGradient>
    </defs>
    <title>zoundbar</title>
    <path fill="url(#linear-gradient)" d="M280.13,202.5c-2,3.27-5.52,6.31-6.43,5.52s5-6.2,5.07-21.54c.11-18.15-5.07-55.13-28.3-71.93C240.44,107.22,229.39,94,220,80.5,226.8,179,243.37,276.23,256.9,373.76c7.44,54.23-.45,107.45-9.13,160.67-2.93,17.82-7.1,35.4-10.71,53.22-1.92,10-8,16.12-17.93,17.93-33.15,5.86-66.3,7.55-99.67,1.58-9.81-1.8-16.69-6.54-18.6-16.91-2.93-16.58-6.54-33-8.68-49.84-1.24-10.15,3.38-15.78,11.73-15.9h.23a3.5,3.5,0,0,0,1.24.11c3.61-.11,9.92-4.29,11.84-10.37,2.37-7.67-7.78-46.23,12-72.95s48-22.44,48-22.44-30.1,10.82-40.81,35.74-5.19,47.92,7,59.64,43.75,3.27,43.75,3.27a209.32,209.32,0,0,0,19.51-9.13.39.39,0,0,1,.23-.11c1.13-1,2.26-1.92,3.38-2.93,26.27-24.47,36.53-57,35.63-91.89-.9-29.31-3.72-59-9.7-87.72a1485.7,1485.7,0,0,1-29.31-219.52c-1-17.7-2.14-35.4-3.16-53.1-.56-9.81-1.24-19.73-1.8-29.65C205.38,12.06,210.56,0,210.56,0l3.27,2.59s.68,4.17,2.48,11.27a.39.39,0,0,1,.11.23c4.85,18.26,17.25,55.13,44.08,86C297.83,143,282.27,199.23,280.13,202.5Z"/>
    <path fill="#fff" d="M21,175.35c.63,6.15.83,10.45,1.55,14.67q35,205,70,410c1.49,8.8,5.61,12,13.73,13.57A332.14,332.14,0,0,0,232,613.39c7.95-1.53,11.45-5.09,12.8-13.07q34.7-204.42,69.78-408.77c.79-4.64.91-9.4,1.54-16.18,0,0-2.64-.21-3.22-.23-4.07-.17-10-2.1-11.71-5.12-4.86-8.7-.83-14.25,9.5-14.35,18.49-.17-16.1-.15,2.39,0,20,.12,26.81,8.28,23.42,28.11Q322.8,264,309,344.14q-22.43,130.69-44.83,261.39c-3.45,20.13-6.52,23.06-26.35,26.91A360.71,360.71,0,0,1,97.68,632c-17.38-3.44-21-6.79-23.88-23.76Q37.23,395.64.76,183c-3.25-19,3.78-27.29,23.1-27.33,53-.1,106.1-.16,159.15.2,4.44,0,11,2.12,12.89,5.39,5.1,8.69.48,14.09-11.16,14.11q-74.15.1-148.3,0Z"/>
  </svg>
</template>
<script>
export default {
  name: 'iconZoundbar'
}
</script>
<style lang="scss">
  .linear-1 {
    stop-color: hsl(var(--accent-2-HS), 31%);
  }
  .linear-2 {
    stop-color: var(--accent-2-color);
  }
  .linear-3 {
    stop-color: var(--accent-color);
  }
</style>
