import { zoundBarInstanceV2 } from '../zoundBarApiV2.service'
const resource = 'settings/security-measures/'

export const SecurityMeasuresService = {
  get () {
    return zoundBarInstanceV2.get(resource)
  },
  put (payload) {
    return zoundBarInstanceV2.put(resource, payload)
  }
}
